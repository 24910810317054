<template>
  <div class="navMini">
    <ul>
      <li v-for="(item, index) in navList" :key="index" @click="changeList(index)">{{ item.name }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        {
          name: "All",
        },
        {
          name: "Brand 品牌",
        },
        {
          name: "Packing 包装",
        },
        {
          name: "Photography 摄影",
        },
        {
          name: "Space 空间",
        },
      ],
    }
  },
  methods: {
    changeList(index) {
      switch (index) {
        case 0:
          this.$store.commit('changeList', 0);
          break;
        case 1:
          this.$store.commit('changeList', 1);
          break;
        case 2:
          this.$store.commit('changeList', 2);
          break;
        case 3:
          this.$store.commit('changeList', 3);
          break;
        case 4:
          this.$store.commit('changeList', 4);
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style scoped lang="less">
.navMini {
  position: relative;
  z-index: 1000000000;
  margin: 100px .2rem -10px 0px;

  ul {
    display: flex;
    justify-content: flex-end;
    padding-left: .2rem;

    li {
      color: #999;
      font-size: 13px;
      cursor: pointer;
    }

    li:not(:first-child) {
      margin-left: 0.2rem;
    }
  }
}

/* 宽度大于1900*/
@media only screen and (min-width:1900px) {
  .navMini {

  margin: 125px .2rem 20px 0px;
    ul {
      li {
        font-size: 16px;
      }
    }
  }
}

/* 屏幕宽度大于1600并且小于1899 */
@media only screen and (min-width:1600px) and (max-width:1899px) {
  .navMini {
    ul {
      li {
        font-size: 14px;
      }
    }
  }
}


/* 宽度大于1100并且小于1400 */
@media only screen and (min-width:1100px) and (max-width:1399px) {
  .navMini {
    margin: 90px .2rem -10px 0px;

    ul {
      li {
        font-size: 12px;
      }
    }
  }
}

/* 宽度大于768并且小于1100 */
@media only screen and (min-width:768px) and (max-width:1099px) {
 .navMini {
    margin: 85px .2rem -10px 0px;

    ul {
      li {
        font-size: 10px;
      }
    }
  }
}

/* 宽度小于768 */
@media only screen and (max-width:768px) {
  .navMini {
    margin: 90px .2rem 20px 0px;
    z-index: 200;

    ul {
      display: flex;
      flex-direction: column;
      text-align: center;

      li {

        margin-left: 0 !important;
        color: #bfbfbf;
        font-size: 14px;
        margin: 4px 0;
        padding: 2px 0;
        cursor: pointer;
        transition: all .5s;
        line-height: 14px;
      }

      li:nth-child(1) {
        margin-top: 0;
      }

      li:hover {
        background: rgba(0, 0, 0, .1);
      }
    }
  }
}


/* 宽度大于1900*/
@media only screen and (min-width:1900px) {}

/* 宽度大于1400并且小于1900 */
@media only screen and (min-width:1400px) and (max-width:1899px) {}

/* 宽度大于1100并且小于1400 */
@media only screen and (min-width:1100px) and (max-width:1399px) {}

/* 宽度大于768并且小于1100 */
@media only screen and (min-width:768px) and (max-width:1099px) {}

/* 宽度小于768 */
@media only screen and (max-width:768px) {}
</style>