<template>
  <div class="work">
    <Nav></Nav>
    <NavMini></NavMini>
    <div class="main">
      <transition-group name="imgGroup" class="imgGroup">
        <div class="con" v-for="(item) in imgList" :key="item.url" @click="jumpDetail(item.path)">
          <img v-lazy="item.url" alt="" />
          <transition name="workCon">
            <div class="mask">
              <div class="iCon">
                <div class="bigCon">{{ item.bigCon }}</div>
                <div class="smallCon">{{ item.smallCon }}</div>
              </div>
            </div>
          </transition>
        </div>
      </transition-group>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../components/FooterInside.vue'
import Nav from "../components/Nav";
import NavMini from "../components/NavMini.vue";
export default {
  components: {
    Nav,
    NavMini,
    Footer
  },
  data() {
    return {
      imgList: [],
    };
  },
  methods: {
    jumpDetail(path) {
      if (path) {
        this.newWindow(path)
      }
    },
    newWindow(path) {
      let route = this.$router.resolve({
        path: path,
      });
      window.open(route.href, '_blank')
    }
  },
  mounted() {
    this.imgList = this.$store.state.workImgList;
  },
  computed: {
    imgListObj() {
      return this.$store.state.workImgList;
    },
  },
  watch: {
    imgListObj(newVal) {
      this.imgList = newVal;
    },
  },


};
</script>

<style scoped lang="less">
.work {
  overflow: hidden;
  
  .main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0.2rem 0rem;

    .con {
      overflow: hidden;
      position: relative;
      width: 49.5%;
      margin-top: 0.1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        width: 100%;
      }

      img[lazy="loading"] {
        width: 18%;
      }
    }

    .con:hover {
      .mask {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .mask {
      transition: all .3s;
      transform: translateY(100%);
      opacity: 0;
      position: absolute;
      top: 81%;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.5);

      .iCon {
        color: #3f3f3f;
        margin: 1.2% 0 0px 2%;
      }

      .bigCon {
        font-size: 0.14rem;
        margin: 0 0 2px 0;
        font-weight: 400;

      }

      .smallCon {
        font-size: 0.09rem;
        font-weight: 30;

      }
    }
  }
}


.imgGroup-enter-active {
  transition: opacity 1s;
}

.imgGroup-leave-active {
  transition: all 0s;
}

.imgGroup-enter {
  opacity: 0;
}

.imgGroup-enter-to {
  opacity: 1;
}

.imgGroup-leave {
  opacity: 1;
}

.imgGroup-leave-to {
  opacity: 0;
}

.imgGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* 宽度大于1900*/
@media only screen and (min-width:1900px) {
  .work {

    .main {
      .mask {
        top: 82%;

        .iCon {
          margin: 1% 0 0px 3%;
        }

        .bigCon {
          font-size: 0.14rem;
          margin: 0 0 2px 0;
        }

        .smallCon {
          font-size: 0.08rem;
        }
      }
    }
  }
}

/* 屏幕宽度大于1600并且小于1899 */
@media only screen and (min-width:1600px) and (max-width:1899px) {
  .work {
    .main {
      .mask {
        top: 82%;

        .iCon {
          margin: 1% 0 0px 2.5%;
        }

        .bigCon {
          font-size: 0.14rem;
          margin: 0 0 2px 0;
        }

        .smallCon {
          font-size: 0.09rem;
        }
      }
    }
  }
}

/* 宽度大于1100并且小于1400 */
@media only screen and (min-width:1100px) and (max-width:1399px) {
  .work {
    .main {
      .mask {
        top: 82%;

        .iCon {
          margin: 1% 0 0px 2%;
        }

        .bigCon {
          font-size: 0.13rem;
          margin: 0 0 2px 0;
        }

        .smallCon {
          font-size: 0.08rem;
        }
      }
    }
  }
}

/* 宽度大于768并且小于1100 */
@media only screen and (min-width:768px) and (max-width:1099px) {
  .work {
    .main {
      .mask {
        top: 82%;

        .iCon {
          margin: 1% 0 0px 2%;
        }

        .bigCon {
          font-size: 0.13rem;
          margin: 0 0 2px 0;
        }

        .smallCon {
          font-size: 0.08rem;
        }
      }
    }
  }


}

/* 宽度小于768 */
@media only screen and (max-width: 768px) {
  .work {
    overflow: hidden;

    .main {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: -10px 0 0 0;

      .con {
      width: 97% !important;
      margin-top: 10px !important;
      cursor: pointer;

        img {
          width: 100%;
        }
      }

      .mask {
        top: 78%;

        .iCon {
          color: #333;
          margin: 1.3% 0 0px 3%;
        }

        .bigCon {
          font-size: 13px;
          margin: 0 0 0px 0;

        }

        .smallCon {
          font-size: 10px;
        }
      }
    }
  }

  .imgGroup {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.specialFoot {
  margin-left: 100px;
}
</style>